var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"my-0",on:{"click":function($event){$event.preventDefault();return _vm.captureMobileEvent.apply(null, arguments)}}},[_c('v-col',{staticClass:"py-2 px-3 mr-2"},[_c('v-card',{staticClass:"py-3 px-3 d-flex program-item-container gap-1",class:_vm.isDesktop
          ? 'justify-space-between align-center'
          : 'flex-column program-item-container-mobile',attrs:{"width":"100%","loading":_vm.isLoading}},[_c('v-card',{staticClass:"program-title d-flex flex-column",attrs:{"flat":"","max-width":"400","width":"400"}},[_c('div',{staticClass:"d-flex flex-row align-center"},[_c('div',{staticClass:"program-title"},[_vm._v(" "+_vm._s(_vm.program.name || "")+" ")]),(_vm.isUserPartOfProgram)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('user-part-icon',{staticClass:"small",staticStyle:{"stroke":"var(--v-success-base)"}})],1)]}}],null,false,3095620987)},[_c('span',[_vm._v("You are a player in this program")])]):_vm._e(),(_vm.hasUserRequested)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('user-requested-icon',{staticClass:"small",staticStyle:{"stroke":"var(--v-warning-base)"}})],1)]}}],null,false,2696054741)},[_c('span',[_vm._v("You have requested to join this program")])]):_vm._e()],1),(_vm.program.days !== undefined)?_c('div',{staticClass:"program-subtitle"},[_vm._v(" "+_vm._s(_vm.program.days.join(", "))+" ")]):_vm._e()]),_c('v-card',{staticClass:"d-flex program-dates",attrs:{"min-width":"120","flat":""}},[_vm._v(" "+_vm._s(_vm.startDateInfo)+" ")]),_c('div',{staticClass:"d-flex w-100",class:_vm.isDesktop
            ? 'align-center justify-space-evenly'
            : 'flex-column-reverse'},[_c('v-card',{staticClass:"d-flex gap-1 chips-container",attrs:{"flat":"","width":"330"}},[_c('sportango-chip',{attrs:{"color":"primary"}},[_vm._v(" "+_vm._s(_vm.program.sport)+" ")]),_c('sportango-chip',{attrs:{"color":"000"}},[_vm._v(" "+_vm._s(_vm.program.category)+" ")]),_c('sportango-chip',{attrs:{"color":"000"}},[_vm._v(" "+_vm._s(_vm.program.plan)+" ")]),_c('sportango-chip',{attrs:{"color":"000"}},[_vm._v(" "+_vm._s(_vm.program.level)+" ")])],1),_c('v-card',{staticClass:"d-flex align-center px-0 gap-1 program-time",attrs:{"flat":"","width":"160","height":"40"}},[_c('div',{staticClass:"d-flex align-center"},[_c('time-icon',{staticClass:"small",attrs:{"stroke":"var(--v-primary-base)"}}),_c('div',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm._f("amPm")(_vm.program.startTime))+",")])],1),_c('div',[_vm._v(" "+_vm._s(_vm.program.duration)+" ")])])],1),_c('user-chip',{attrs:{"small":true,"maxWidth":_vm.isDesktop ? 120 : 200,"user":_vm.headCoach}}),(!_vm.hideMenu)?_c('v-btn',{staticClass:"btn-more",attrs:{"icon":""},on:{"click":_vm.captureEvent}},[_c('more-icon',{staticStyle:{"transform":"rotate(90deg)"},attrs:{"stroke":"var(--v-primary-base)"}})],1):_vm._e()],1)],1),(!_vm.hideMenu)?_c('menu-or-bottom-sheet',{attrs:{"header":_vm.program.name,"event":_vm.event},model:{value:(_vm.isEventCaptured),callback:function ($$v) {_vm.isEventCaptured=$$v},expression:"isEventCaptured"}},[_c('v-list',{staticClass:"py-0",attrs:{"dense":""}},[_vm._l((_vm.modes),function(m){return _c('program-item-action',{key:m.path,attrs:{"action":m.text,"disabled":m.disabled,"to":("/programs/view/" + (_vm.program.id) + "?mode=" + (m.path))},on:{"click":function($event){_vm.isEventCaptured = false}}})}),(_vm.currentUser && _vm.currentUser.permissions.hasAdminAccess)?_c('program-item-action',{attrs:{"to":("/programs/payment/" + (_vm.program.id)),"action":"Payment"}}):_vm._e(),(
          _vm.currentUser &&
          _vm.currentUser.permissions.hasAdminAccess &&
          _vm.$store.getters.featureFlags['delete-program']
        )?_c('program-item-action',{attrs:{"action":"Delete","to":("/programs/view/" + (_vm.program.id) + "?mode=delete")}}):_vm._e()],2)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }