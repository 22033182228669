














































































































































import { Program } from "@sportango/backend";
import {
  AllUsersMixin,
  CaptureEvent,
  CurrentUserMixin,
  LoadingMixin
} from "@/mixins/Helpers";
import { ResponsiveMixin } from "@/mixins/Responsive";
import Component, { mixins } from "vue-class-component";
import { Prop } from "vue-property-decorator";
import TimeIcon from "@/assets/icons/saxcons/clock-linear.svg";
import MoreIcon from "@/assets/icons/saxcons/more-linear.svg";
import UserChip from "@/components/common/UserChip.vue";
import { BaseUser } from "@sportango/backend";
import dayjs from "dayjs";
import ProgramItemAction from "./ProgramItemAction.vue";
import { shouldAllowUser, USER_TYPES } from "@/store/actions/users";
import UserPartIcon from "@/assets/icons/saxcons/user-tick-linear.svg";
import UserRequestedIcon from "@/assets/icons/saxcons/user-minus-linear.svg";
import MenuOrBottomSheet from "../Navigation/MenuOrBottomSheet.vue";
import { SportangoChip } from "@/components/common/overrides";

export interface Path {
  text: string;
  path: string;
  allowedUsers: Array<USER_TYPES>;
  disabled?: boolean;
}
@Component({
  name: "program-item",
  components: {
    SportangoChip,
    MenuOrBottomSheet,
    TimeIcon,
    ProgramItemAction,
    MoreIcon,
    UserChip,
    UserPartIcon,
    UserRequestedIcon
  }
})
export default class ProgramItem extends mixins(
  LoadingMixin,
  ResponsiveMixin,
  AllUsersMixin,
  CurrentUserMixin,
  CaptureEvent
) {
  @Prop({ required: true })
  private program!: Program;
  @Prop({ required: false, default: false })
  hideMenu!: boolean;

  allModes: Array<Path> = [
    {
      path: "view",
      text: "View",
      allowedUsers: ["PLAYER", "COACH", "ADMIN"]
    },
    {
      path: "edit",
      text: "Reschedule",
      allowedUsers: ["ADMIN"]
    },
    {
      path: "pricing",
      text: "Pricing",
      allowedUsers: ["ADMIN"]
    },
    {
      path: "players",
      text: "Players",
      allowedUsers: ["ADMIN"]
    },
    {
      path: "copy-program",
      text: "Copy Program",
      allowedUsers: ["ADMIN"]
    }
  ];
  get headCoach(): BaseUser | null {
    if (this.program.coaches) {
      const headCoachId = this.program.coaches[0];
      if (headCoachId) {
        return this.allUsers.find((u) => u.uid === headCoachId) || null;
      }
    }
    return null;
  }

  get startDateInfo(): string {
    return `${dayjs(this.program.startDate).format("MMM DD")} - ${dayjs(
      this.program.endDate
    ).format("MMM DD")}`;
  }

  get modes(): Array<Path> {
    if (this.currentUser) {
      const permissions = this.currentUser.permissions;
      const baseModes = this.allModes.filter((p) =>
        p.allowedUsers
          .map((u) => shouldAllowUser(u, permissions))
          .includes(true)
      );
      baseModes.push({
        path: "request",
        text: this.hasUserRequested
          ? "Request is already sent"
          : this.isUserPartOfProgram
          ? "Already attending the program"
          : "Request to Join",
        allowedUsers: ["PLAYER"],
        disabled: this.hasUserRequested || this.isUserPartOfProgram
      });
      return baseModes;
    }
    return [];
  }
  get isUserPartOfProgram(): boolean {
    if (this.program.coaches?.find((c) => c === this.currentUser?.uid)) {
      return true;
    } else if (
      this.program.programPlayers?.find((p) => p.uid === this.currentUser?.uid)
    ) {
      return true;
    }
    return false;
  }
  get hasUserRequested(): boolean {
    if (
      this.program.playerRequests &&
      this.program.playerRequests?.find((r) => r.uid === this.currentUser?.uid)
    ) {
      return true;
    }
    return false;
  }
}
