



































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";
import EditIcon from "@/assets/icons/saxcons/edit-linear.svg";
import CopyIcon from "@/assets/icons/saxcons/copy-linear.svg";
import ViewIcon from "@/assets/icons/saxcons/info-circle-linear.svg";
import RescheduleIcon from "@/assets/icons/saxcons/calendar-edit-linear.svg";
import PlayersIcon from "@/assets/icons/saxcons/profile-2user-linear.svg";
import PriceIcon from "@/assets/icons/saxcons/dollar-square-broken.svg";
import AddIcon from "@/assets/icons/saxcons/add-broken.svg";
import PaymentIcon from "@/assets/icons/saxcons/dollar-circle-linear.svg";
import UserIconTick from "@/assets/icons/saxcons/user-tick-linear.svg";
import TrashIcon from "@/assets/icons/saxcons/trash-linear.svg";
type ActionType =
  | "View"
  | "Reschedule"
  | "Pricing"
  | "Players"
  | "Request to Join"
  | "Already attending the program"
  | "Request is already sent"
  | "Payment"
  | "Copy Program"
  | "Delete";
@Component({
  name: "program-item-action",
  components: {
    PaymentIcon,
    AddIcon,
    EditIcon,
    ViewIcon,
    RescheduleIcon,
    PlayersIcon,
    PriceIcon,
    UserIconTick,
    CopyIcon,
    TrashIcon
  }
})
export default class ProgramItemAction extends Vue {
  @Prop({ required: true })
  action!: ActionType;
  @Prop({ required: true })
  to!: string;
  @Prop({ required: false })
  disabled!: boolean;

  get icon(): string | null {
    switch (this.action) {
      case "View":
        return "ViewIcon";
      case "Reschedule":
        return "EditIcon";
      case "Players":
        return "PlayersIcon";
      case "Copy Program":
        return "CopyIcon";
      case "Pricing":
        return "PriceIcon";
      case "Request to Join":
        return "PlayersIcon";
      case "Request is already sent":
        return "UserIconTick";
      case "Already attending the program":
        return "UserIconTick";
      case "Payment":
        return "PaymentIcon";
      case "Delete":
        return "TrashIcon";
    }
    return null;
  }

  get color(): string {
    switch (this.action) {
      case "View":
        return "info";
      case "Reschedule":
        return "warning";
      case "Pricing":
        return "info";
      case "Players":
        return "primary";
      case "Copy Program":
        return "success";
      case "Request is already sent":
        return "warning";
      case "Already attending the program":
        return "success";
      case "Payment":
        return "primary";
      case "Delete":
        return "error";
    }
    return "primary";
  }

  get subTitle(): string | null {
    switch (this.action) {
      case "View":
        return "View more information about this event";
    }
    return null;
  }
}
