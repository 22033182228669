






















































import Component, { mixins } from "vue-class-component";
import AddIcon from "@/assets/icons/saxcons/add-broken-white.svg";
import { ResponsiveMixin } from "@/mixins/Responsive";
import { CurrentUserMixin, LoadingMixin } from "@/mixins/Helpers";
import { Program } from "@sportango/backend";
import ProgramItem from "@/components/programs/ProgramItem.vue";
import NoData from "@/components/common/NoData.vue";
import { WatchLoading } from "@/decorators/Loading";
import { GetProgramsPayload } from "@/store/actions/programs";
import { SportangoSelect } from "@/components/Inputs/overrides";
import { Watch } from "vue-property-decorator";
import SportangoWeekPicker from "@/components/Inputs/SportangoWeekPicker.vue";
import AppDialog from "@/components/Navigation/AppDialog.vue";
@Component({
  name: "programs-directory",
  components: {
    SportangoWeekPicker,
    AppDialog,
    SportangoSelect,
    AddIcon,
    ProgramItem,
    NoData
  }
})
export default class ProgramsDirectory extends mixins(
  ResponsiveMixin,
  LoadingMixin,
  CurrentUserMixin
) {
  sport: Program.SportEnum = "Tennis";
  startDate: Date = new Date();
  readonly sportsOptions = Program.options.sport;

  async mounted(): Promise<void> {
    await this.getPrograms();
  }

  @WatchLoading()
  @Watch("sport")
  @Watch("startDate")
  async getPrograms() {
    const queryPayload: GetProgramsPayload = {
      sport: this.sport,
      startDate: this.startDate
    };
    await this.$store.dispatch("getPrograms", queryPayload);
  }

  get programs(): Array<Program> {
    return this.$store.getters.programs;
  }

  get allProgramsFetched(): boolean {
    return this.$store.getters.allProgramsFetched;
  }
  get canAddUsers(): boolean {
    return !!this.currentUser?.permissions.hasAdminAccess;
  }

  get isPaymentsView(): boolean {
    return (
      this.$route.fullPath.includes("payments/programs") ||
      this.$route.path.startsWith("/payments")
    );
  }
  get hideItemMenu(): boolean {
    if (this.isPaymentsView) {
      return true;
    }
    return false;
  }

  get isViewOnly(): boolean {
    if (this.isPaymentsView) {
      return true;
    }
    return false;
  }

  get width(): string | number {
    if (this.isDesktop) {
      return innerWidth * 0.75;
    }
    return "100%";
  }

  get isDialogOpen(): boolean {
    if (this.isPaymentsView) {
      if (this.$route.params.id) {
        return true;
      }
    }
    return false;
  }

  itemClickLink(programId?: string): string | null {
    if (this.isPaymentsView) {
      return `/payments/programs/run/${programId}`;
    }
    return null;
  }
}
